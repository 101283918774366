.header {
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #38dea8;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 50px;
  &__title {
    border-bottom: 1px solid rgba(27, 31, 59, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}