.occupation-list {
  &__container {
    overflow-y: auto;
    max-height: 400px;
    padding-left: 41px;
  }
  &__color {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 5px;
    margin-right: 5px;
  }
  &__table {
    width: calc(100% - 32px);
  }
  &__td {
    width: calc(100% / 14);
    font-size: 12px;
    padding: 4px;
    border-top: 1px solid black;
    border-right: 1px solid black;
    display: inline-block;
    position: relative;
    &:first-child {
      border-left: 1px solid black;
    }
    &:hover {
      & > .occupation-list__tooltip {
        //display: block;
      }
    }
  }
  &__tooltip {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
  }
}